import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { FiLink } from 'react-icons/fi';

import {
  Container,
  BoxFinalResultTextRightSide,
  BoxFinalResultValueRightSide,
  Wrap,
  LeftContainer,
  TextContainer,
  Header,
  HeaderLine,
  Body,
  BoxFooter,
  BottomContainer,
  Span,
} from './styles';
import ChartAndTopicsComponent from './ChartAndTopicsComponent';

interface TopicValueScore {
  name: string;
  value: number;
  benchmark: number;
  rowOrder: number;
  columnSide: string;
}

interface ApillarTitle {
  title: string;
  rowOrder: number;
  columnSide: string;
}

interface Props {
  aPillarTitles: ApillarTitle[];
  topicValueScores: TopicValueScore[];
  customerOrientationIndex: number;
}

const BubbleTree: React.FC<Props> = ({
  aPillarTitles,
  topicValueScores,
  customerOrientationIndex,
}) => {
  const [textReport, setTextReport] = useState('');
  useEffect(() => {
    if (customerOrientationIndex >= 90 && customerOrientationIndex <= 100) {
      setTextReport(
        'WOW, gratuliere, Du bist auf einem ausgezeichneten Weg und hast ein Gütesiegel verdient. Schön, dass es Organisationen gibt, die verstanden haben, dass Ihre Kund:innen und Unternehmen eine Einheit bilden. Bleibe so kundenzentriert. Wir helfen gerne bei weiteren Schritten, damit es auch so bleibt!',
      );
    }

    if (customerOrientationIndex >= 80 && customerOrientationIndex <= 89) {
      setTextReport(
        'Herzlichen Glückwunsch, Du würdest mit dem Ergebnis noch das TSÖ Gütesiegel von uns erhalten. Deine Organisation hat Potenzial für Großes. Du denkst das Thema Kundenorientierung schon ganzheitlich, aber sehen es das Management und deine Kund:innen das genauso? Finde es heraus mit TSÖ.',
      );
    }

    if (customerOrientationIndex >= 70 && customerOrientationIndex <= 79) {
      setTextReport(
        'Jetzt aber ran ans Thema! Du weißt, dass es in vielen Bereichen Potentiale gibt. Warte nicht, bis es eng wird, sondern handle noch heute. Pack das Thema Kundenorientierung intern und extern an. Starte dafür mit einer Bestandsaufnahme, wo Kund:innen und Management Verbesserungspotential sehen.',
      );
    }

    if (customerOrientationIndex <= 69) {
      setTextReport(
        'Was soll man sagen? Toll, dass deine Kund:innen noch treu sind, aber besser Du änderst jetzt Entscheidendes. Das kommt wahrscheinlich  auch nicht überraschend, aber frage mal mit unserer anonymen Erhebung deine Kund:innen und dein Management und packe genau dort an. Wir helfen gern dabei!',
      );
    }
  }, [customerOrientationIndex]);

  return (
    <Wrap>
      <div style={{ marginBottom: '332px' }}>
        <BottomContainer>
          <Link to={`/aftersurvey`} target="_blank">
            <BoxFooter>
              {/* <FiLink size={22} color={'#ff0000'} /> */}
              <Span>
                <h4>DEIN ERGEBNIS</h4>
              </Span>
            </BoxFooter>
          </Link>
        </BottomContainer>
      </div>
      <Container>
        <ChartAndTopicsComponent
          aPillarTitles={aPillarTitles}
          topicValueScores={topicValueScores}
          customerOrientationIndex={customerOrientationIndex}
        ></ChartAndTopicsComponent>

        <LeftContainer>
          <TextContainer>
            <Header>
              <BoxFinalResultTextRightSide>
                <span>CUSTOMER ORIENTATION INDEX</span>
              </BoxFinalResultTextRightSide>
              <BoxFinalResultValueRightSide>
                <span>
                  {customerOrientationIndex === -99
                    ? 'k/a'
                    : customerOrientationIndex}
                </span>
              </BoxFinalResultValueRightSide>
              <HeaderLine></HeaderLine>
            </Header>
            <Body>
              <p>{textReport}</p>
            </Body>
          </TextContainer>
        </LeftContainer>
      </Container>
      <BottomContainer>
        <p style={{ margin: -107 }}>
          Die ausgewiesenen Indexwerte können zwischen 0 (= Minimum) und 100 (=
          Maximum) liegen. Somit gilt, je höher der Indexwert, desto besser die
          Leistung.
        </p>
      </BottomContainer>
    </Wrap>
  );
};

export default BubbleTree;
